.sticky-header{
    top: calculateRem(80px);
    @screen lg{
        top: calculateRem(110px);
    }
    &--large{
        @screen lg{
            top: calculateRem(145px);
        }
    }
}

.page-content{
    @apply text-sm leading-relaxed;
    @screen md{
        @apply text-base leading-relaxed;
    }

    @include m(smaller){
        font-size: 0.8em;
        @screen lg{
            font-size: 0.9em;
        }
    }

    *{
        line-height: 1.6;
        @screen lg{
            line-height: 1.8;
        }
        &:first-child{
            @apply mt-0;
        }
    }
    a{
        @apply font-bold border-b border-primary;
        &:hover{
            @apply border-gray-200;
        }
    }
    p{
        margin-bottom: calculateRem(15px);
        @screen lg{
            margin-bottom: calculateRem(20px);
        }
        &:last-of-type{
            @apply mb-0;
        }
    }
    h1,h2,h3,h4,h5,h6{
        margin-top: calculateRem(20px);
        margin-bottom: calculateRem(14px);
        @apply leading-relaxed font-medium;
    }
    h1{
        @apply text-3xl;
    }
    h2{
        @apply text-2xl;
    }
    h3{
        @apply text-xl;
    }
    h4{
        @apply text-lg;
    }
    h5{
        @apply text-sm;
    }
    h6{
        @apply text-xs;
    }
    ul:not(.blocks-gallery-grid), ol:not(.blocks-gallery-grid){
        list-style: none;
        margin: calculateRem(15px) 0;
        li{
            margin-left: calculateRem(25px);
            position: relative;
            &:before{
                @apply bg-primary;
                content: '';
                position: absolute;
                top: calculateRem(9px);
                left: calculateRem(-20px);
                width: calculateRem(6px);
                height: calculateRem(6px);
                @screen lg{
                    top: calculateRem(10px);
                }
            }
        }
        ul, ol {
            list-style: auto;
            margin: calculateRem(5px) 0;
            li{
                margin-left: calculateRem(30px);
                &:before{
                    @apply bg-primary;
                    content: '';
                    position: absolute;
                    top: calculateRem(9px);
                    left: calculateRem(-24px);
                    width: calculateRem(6px);
                    height: calculateRem(6px);
                }
            }
            ul, ol {
                li{
                    &:before{
                        @apply bg-primary;
                        content: '';
                        position: absolute;
                        top: calculateRem(10px);
                        left: calculateRem(-24px);
                        width: calculateRem(4px);
                        height: calculateRem(4px);
                    }
                }
            }

        }
    }
}
