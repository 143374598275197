.wp-block-quote {
	overflow-wrap: break-word; // Break long strings of text without spaces so they don't overflow the block.

	&.is-style-large,
	&.is-large {
		margin-bottom: 1em;
		padding: 0 1em;

		p {
			font-size: 1.5em;
			font-style: italic;
			line-height: 1.6;
		}

		cite,
		footer {
			font-size: 1.125em;
			text-align: right;
		}
	}
}
