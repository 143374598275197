.accordion {
    .accordion-item{
        @apply mb-4;
        .plus-more{
            @apply text-primary w-3 h-3 fill-current stroke-current;
        }
        .accordion-link{

            @screen lg{
                font-size: .92rem;
            }
            svg{
                @include transition(all .3s);
            }
            .more{
                @include transition(all .3s);
            }

            &.active{
                @apply bg-white border-white;
                svg{
                    @include transform(rotate(90deg));

                }
                .more{
                    @apply opacity-0;
                }
                &.accordion-link--product{
                    @apply bg-primary border-primary text-white;
                    .plus-more{
                        @apply text-white;
                    }
                }
            }

        }
        .accordion-content{
            @apply hidden py-2;
            &.active{
                @apply block;
            }
        }

    }
}