


#menu-icon {
  width: calculateRem(25px);
  cursor: pointer;
  path {
    fill: color(black);
  }
}

.header-app{
  .header-app__inner{
    height: calculateRem(77px);
    @screen lg{
      height: calculateRem(86px);
    }
  }
  &.header-app--with-banner{
    @screen lg{
      height: calculateRem(123px);
    }
  }
}


.mobile-menu{
  @apply hidden text-black absolute bg-white top-full left-0 right-0 overflow-auto px-3 py-2;
  @screen lg{
    @apply px-6 overflow-visible;
  }
  @screen xl {
    @apply block static z-10 pt-0 px-0 pb-0 bg-transparent;
    max-height: inherit !important;
  }
  &.opened{
    @apply block;
  }
}

.products-megamenu{
  @screen xl{
    @apply absolute left-0 right-0 opacity-0 pointer-events-none;
    visibility: hidden;
    top: calculateRem(86px);
    @include transition(all .2s);
  }
}
.primary-nav{
  .primary-nav-li{
    @screen xl{
      &:hover{
        .products-megamenu{
          @apply opacity-100 pointer-events-auto;
          visibility: visible;
        }
      }
    }
    .link-menu{
      @apply text-base;
      @screen xl{
        font-size: 0.81rem;
      }
      @screen 2xl{
        @apply text-sm;
      }
    }
  }
}

.header-app--with-banner{
  .products-megamenu{
    @screen xl{
      top: calculateRem(123px);
    }
  }
}


.favourites-trigger{
  &.not-shown{
    display: none !important;
  }
}

.cart-favourites{
  @apply fixed pointer-events-none;
  visibility: hidden;
  @include opacity(0);
  @include transition(all .18s $transitioneffect);
  left: 0;
  right: 0;
  top: calculateRem(38px);
  @screen md{
    top: 100%;
    @apply absolute;
    left: auto;
    min-width: calculateRem(320px);
    right: calculateRem(-40px);
  }

  &.shown{
    @apply block;
    pointer-events: auto;
    @include opacity(1);
    @include transform(translateY(40px));
    visibility: visible;
  }
  .cart-favourites-inner{
    overflow-y: auto;
    max-height: calculateRem(250px);
    @screen md{
      max-height: calculateRem(300px);
    }
  }
  .cart-favourites-item{
    @apply border-b border-gray-200;
    &:last-child{
      @apply border-b-0;
    }
  }
}
.quantity-favourites{
  @apply bg-primary rounded-full text-white text-xs font-semibold w-5 h-5 items-center text-center justify-center flex absolute;
  bottom: calculateRem(-7px);
  right: calculateRem(-7px);
}


.header-app-overlay{
  @apply relative bg-white overflow-hidden;
  height: 0;
  
  .search-form-content{
    @apply hidden;
    .text-input-search{
      @apply opacity-0;
    }
  }
  /*.products-megamenu{
    @apply hidden;
  }*/
}

.page-overlay-header{
  @apply fixed z-10 inset-0 hidden;
  @include opacity(0);
  background: rgba(color(black),0.5);
  &.active{
    cursor: url(../images/cursor-close.png) 20 20,default;
    cursor: -webkit-image-set(url(../images/cursor-close.png) 1x, url(../images/cursor-close@2x.png) 2x) 20 20,default;
  }
}
/*
.products-megamenu__inner{
  @apply opacity-0;
}
*/
