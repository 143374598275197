// Import styles for rendering the static content of deprecated gallery versions.
@import "./deprecated.scss";

// Styles for current version of gallery block.
.wp-block-gallery.has-nested-images {
	display: flex;
	flex-wrap: wrap;
	// Need bogus :not(#individual-image) to override long :not()
	// specificity chain on default image block on front end.
	figure.wp-block-image:not(#individual-image) {
		// Add space between thumbnails, and unset right most thumbnails later.
		margin: 0 var(--gallery-block--gutter-size, #{$grid-unit-20}) var(--gallery-block--gutter-size, #{$grid-unit-20}) 0;

		&:last-of-type:not(#individual-image) {
			margin-right: 0;
		}

		width: calc(50% - (var(--gallery-block--gutter-size, #{$grid-unit-20}) / 2));

		&:nth-of-type(even) {
			margin-right: 0;
		}
	}

	figure.wp-block-image {
		display: flex;
		flex-grow: 1;
		justify-content: center;
		position: relative;
		margin-top: auto;
		margin-bottom: auto;
		flex-direction: column;

		> div,
		> a {
			margin: 0;
			flex-direction: column;
			flex-grow: 1;
		}

		img {
			display: block;
			height: auto;
			max-width: 100%;
			width: auto;
		}

		figcaption {
			background: linear-gradient(0deg, rgba($color: $black, $alpha: 0.7) 0, rgba($color: $black, $alpha: 0.3) 70%, transparent);
			bottom: 0;
			color: $white;
			font-size: $default-font-size;
			left: 0;
			margin-bottom: 0;
			max-height: 60%;
			overflow: auto;
			padding: 0 8px 8px;
			position: absolute;
			text-align: center;
			width: 100%;
			box-sizing: border-box;

			img {
				display: inline;
			}

			a {
				color: inherit;
			}
		}

		&.is-style-rounded {
			> div,
			> a {
				flex: 1 1 auto;
			}
			figcaption {
				flex: initial;
				background: none;
				color: inherit;
				margin: 0;
				padding: 10px 10px 9px;
				position: relative;
			}
		}
	}

	figcaption {
		flex-grow: 1;
		flex-basis: 100%;
		text-align: center;
	}

	// Non cropped images.
	&:not(.is-cropped) {

		figure.wp-block-image:not(#individual-image) {
			margin-top: 0;
			margin-bottom: auto;
			img {
				margin-bottom: var(--gallery-block--gutter-size, #{$grid-unit-20});
			}

			figcaption {
				bottom: var(--gallery-block--gutter-size, #{$grid-unit-20});
			}
		}
	}

	// Cropped Images.
	&.is-cropped figure.wp-block-image:not(#individual-image) {
		align-self: inherit;
		> div:not(.components-drop-zone),
		> a {
			display: flex;
		}

		a,
		img {
			width: 100%;
			flex: 1 0 0%;
			height: 100%;
			object-fit: cover;
		}
	}

	&.columns-1 figure.wp-block-image:not(#individual-image) {
		margin-right: 0;
		width: 100%;
	}

	// Beyond mobile viewports, we allow up to 8 columns.
	@include break-small {
		@for $i from 3 through 8 {
			&.columns-#{ $i } figure.wp-block-image:not(#individual-image) {
				margin-right: var(--gallery-block--gutter-size, #{$grid-unit-20});
				width: calc(#{math.div(100%, $i)} - (var(--gallery-block--gutter-size, #{$grid-unit-20}) * #{math.div($i - 1, $i)}));

			}

			// Prevent collapsing margin while sibling is being dragged.
			&.columns-#{$i} figure.wp-block-image:not(#individual-image).is-dragging ~ figure.wp-block-image:not(#individual-image) {
				margin-right: var(--gallery-block--gutter-size, #{$grid-unit-20});
			}
		}
		// Unset the right margin on every rightmost gallery item to ensure center balance.
		@for $column-count from 1 through 8 {
			&.columns-#{$column-count} figure.wp-block-image:not(#individual-image):nth-of-type(#{ $column-count }n) {
				margin-right: 0;
			}
		}
		// If number of columns not explicitly set default to 3 columns if 3 or more images.
		&.columns-default {
			figure.wp-block-image:not(#individual-image) {
				margin-right: var(--gallery-block--gutter-size, #{$grid-unit-20});
				width: calc(33.33% - (var(--gallery-block--gutter-size, 16px) * #{math.div(2, 3)}));
			}
			figure.wp-block-image:not(#individual-image):nth-of-type(3n+3) {
				margin-right: 0;
			}
			// If only 2 child images use 2 columns.
			figure.wp-block-image:not(#individual-image):first-child:nth-last-child(2),
			figure.wp-block-image:not(#individual-image):first-child:nth-last-child(2) ~ figure.wp-block-image:not(#individual-image) {
				width: calc(50% - (var(--gallery-block--gutter-size, 16px) * 0.5));
			}
			// For a single image set to 100%.
			figure.wp-block-image:not(#individual-image):first-child:nth-last-child(1) {
				width: 100%;
			}
		}
	}

	// Apply max-width to floated items that have no intrinsic width.
	&.alignleft,
	&.alignright {
		max-width: $content-width * 0.5;
		width: 100%;
	}

	// If the gallery is centered, center the content inside as well.
	&.aligncenter {
		justify-content: center;
	}
}
