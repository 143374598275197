.wp-block-image {
	margin: 1em 0 1em 0;

	img {
		height: auto;
		max-width: 100%;
		vertical-align: bottom;
	}

	&:not(.is-style-rounded) {
		> a,
		img {
			border-radius: inherit;
		}
	}

	&.aligncenter {
		text-align: center;
	}

	&.alignfull img,
	&.alignwide img {
		height: auto;
		width: 100%;
	}

	.alignleft,
	.alignright,
	.aligncenter {
		display: table;

		> figcaption {
			display: table-caption;
			caption-side: bottom;
		}
	}

	.alignleft {
		/*rtl:ignore*/
		float: left;
		/*rtl:ignore*/
		margin-left: 0;
		margin-right: 1em;
		margin-top: 0.5em;
		margin-bottom: 0.5em;
	}

	.alignright {
		/*rtl:ignore*/
		float: right;
		/*rtl:ignore*/
		margin-right: 0;
		margin-left: 1em;
		margin-top: 0.5em;
		margin-bottom: 0.5em;
	}

	.aligncenter {
		margin-left: auto;
		margin-right: auto;
	}

	// Supply caption styles to images, even if the theme hasn't opted in.
	// Reason being: the new markup, <figcaptions>, are not likely to be styled in the majority of existing themes,
	// so we supply the styles so as to not appear broken or unstyled in those themes.
	figcaption {
		@apply font-light text-gray-500 text-sm text-center italic;
		@include caption-style();
	}

	// Variations
	&.is-style-rounded img {
		// We use an absolute pixel to prevent the oval shape that a value of 50% would give
		// to rectangular images. A pill-shape is better than otherwise.
		border-radius: 9999px;
	}

	// The following variation is deprecated.
	// The CSS is kept here for the time being, to support blocks using the old variation.
	&.is-style-circle-mask img {
		// We use an absolute pixel to prevent the oval shape that a value of 50% would give
		// to rectangular images. A pill-shape is better than otherwise.
		border-radius: 9999px;

		// If a browser supports it, we will switch to using a circular SVG mask.
		// The stylelint override is necessary to use the SVG inline here.
		@supports (mask-image: none) or (-webkit-mask-image: none) {
			/* stylelint-disable */
			mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
			/* stylelint-enable */
			mask-mode: alpha;
			mask-repeat: no-repeat;
			mask-size: contain;
			mask-position: center;
			border-radius: 0;
		}
	}
}

.wp-block-image figure {
	margin: 0;
}
