// Deprecated gallery styles pre refactoring to use nested image blocks.
// https://github.com/WordPress/gutenberg/pull/25940.
.wp-block-gallery,
.blocks-gallery-grid {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	padding: 0;
	// Some themes give all <ul> default margin instead of padding.
	margin: 0;

	.blocks-gallery-image,
	.blocks-gallery-item {
		// Add space between thumbnails, and unset right most thumbnails later.
		margin: 0 1em 1em 0;
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		justify-content: center;
		position: relative;

		// On mobile and responsive viewports, we allow only 1 or 2 columns at the most.
		width: calc(50% - 1em);

		&:nth-of-type(even) {
			margin-right: 0;
		}

		figure {
			margin: 0;
			height: 100%;
			display: flex;
			align-items: flex-end;
			justify-content: flex-start;
		}

		img {
			display: block;
			max-width: 100%;
			height: auto;
			width: auto;
		}

		figcaption {
			position: absolute;
			bottom: 0;
			width: 100%;
			max-height: 100%;
			overflow: auto;
			padding: 3em 0.77em 0.7em;
			color: $white;
			text-align: center;
			font-size: 0.8em;
			background: linear-gradient(0deg, rgba($color: $black, $alpha: 0.7) 0, rgba($color: $black, $alpha: 0.3) 70%, transparent);
			box-sizing: border-box;
			margin: 0;
			z-index: 2;
			img {
				display: inline;
			}
		}
	}

	figcaption {
		flex-grow: 1;
	}

	// Cropped
	&.is-cropped .blocks-gallery-image,
	&.is-cropped .blocks-gallery-item {
		a,
		img {
			width: 100%;
			height: 100%;
			flex: 1;
			object-fit: cover;
		}
	}

	&.columns-1 .blocks-gallery-image,
	&.columns-1 .blocks-gallery-item {
		width: 100%;
		margin-right: 0;
	}

	// Beyond mobile viewports, we allow up to 8 columns.
	@include break-small {
		@for $i from 3 through 8 {
			&.columns-#{ $i } .blocks-gallery-image,
			&.columns-#{ $i } .blocks-gallery-item {
				width: calc(#{ math.div(100%, $i) } - #{ math.div(1em * ( $i - 1 ), $i) });
				margin-right: 1em;
			}
		}

		// Unset the right margin on every rightmost gallery item to ensure center balance.
		@for $column-count from 1 through 8 {
			&.columns-#{ $column-count } .blocks-gallery-image:nth-of-type(#{ $column-count }n),
			&.columns-#{ $column-count } .blocks-gallery-item:nth-of-type(#{ $column-count }n) {
				margin-right: 0;
			}
		}
	}

	// Last item always needs margins reset.
	.blocks-gallery-image:last-child,
	.blocks-gallery-item:last-child {
		margin-right: 0;
	}

	// Apply max-width to floated items that have no intrinsic width.
	&.alignleft,
	&.alignright {
		max-width: $content-width * 0.5;
		width: 100%;
	}

	// If the gallery is centered, center the content inside as well.
	&.aligncenter {
		.blocks-gallery-item figure {
			justify-content: center;
		}
	}
}
.wp-block-gallery {
	// Non cropped images.
	&:not(.is-cropped) {
		.blocks-gallery-item {
			align-self: flex-start;
		}
	}
}
