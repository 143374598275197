.btn {
    @apply px-8 py-4 tracking-widest text-sm uppercase font-semibold relative;
}

.btn-solid{
    @apply overflow-hidden;
    &:after{
        content: '';
        @apply absolute border border-primary inset-0 z-0;
    }
    &:before{
        content: '';
        @apply inset-0 absolute bg-primary;
        @include transform(translateY(100%));
        @include transition(transform .2s $transitioneffect);
    }
    span{
        @apply relative z-10 text-primary;
        @include transition(color .2s);
    }
    &:hover{
        span{
            @apply text-white;
        }
        &:before{
            @include transform(translateY(0%));
        }
    }


    
}
.btn-white{
    @apply bg-white text-primary;

    &:hover{
        @apply bg-primary text-white;
    }
}
.btn-favourites{
    @apply text-sm py-3 px-6 bg-primary text-white rounded-full;

    &:hover{
        @apply bg-black;
    }
}

.btn-bordered {
    @apply overflow-hidden text-white;
    &:after{
        content: '';
        @apply absolute border border-primary inset-0 z-0;
    }
    &:before{
        content: '';
        @apply inset-0 absolute bg-primary;
        @include transform(translateY(100%));
        @include transition(transform .2s $transitioneffect);
    }
    span{
        @apply relative z-10;
    }
    &:hover{
        &:before{
            @include transform(translateY(0));
        }
    }
}
