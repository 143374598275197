h1,
h2,
h3,
h4,
h5,
h6 {
	// Break long strings of text without spaces so they don't overflow the block.
	overflow-wrap: break-word;

	&.has-background {
		padding: $block-bg-padding--v $block-bg-padding--h;
	}
}
