/**
 * Global
 */

 body {
  max-width: 100vw;
  &:before {
    content: "mobile";
    display: none;
    @screen lg{
      content:  "desktop";
    }
  }

}

.main {
  min-height: 100vh;
  padding-top: calculateRem(77px);
  @screen lg{
    padding-top: calculateRem(86px);
    &.main-app--with-banner{
      padding-top: calculateRem(123px);
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply mb-2 font-semibold;
}

p {
  @apply mb-3;
  &:last-of-type{
    @apply mb-0;
  }
}

button, input {
  @include transition(all 0.35s $transitioneffect);
  outline: 0 !important;
  box-shadow: 0;
}

a {
  @apply no-underline;
  outline: 0 !important;
  @include transition(all 0.35s $transitioneffect);
}


.compat-object-fit{
    background-size: cover;
    background-position: center center;
    picture, img{
        visibility: hidden;
        @include opacity(0);
    }

}



