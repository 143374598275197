.image-news{
    height: calculateRem(250px);
}
.share-container {
    @apply mt-4;
    @screen lg{
        @apply mt-10;
    }
    h4{
        font-size: 0.8rem;
    }
    li{
        @apply ml-2 mb-0;
    }
}
.navigation {
    .screen-reader-text{
        @apply sr-only;
    }
    .nav-links{
        @apply flex flex-wrap justify-center items-center;
        .nav-next, .nav-previous{
            @apply mx-2;
            a{
                @apply flex text-sm text-primary items-center;
                &:hover{
                    @apply text-gray-600;
                }
            }
        }
    }
}
