@use 'sass:math';

$break-huge: 1440px;
$break-wide: 1280px;
$break-xlarge: 1080px;
$break-large: 960px;    // admin sidebar auto folds
$break-medium: 782px;   // adminbar goes big
$break-small: 600px;
$break-mobile: 480px;
$break-zoomed-in: 280px;

/**
 * Colors
 */

// WordPress grays.
$black: #000;           // Use only when you truly need pure black. For UI, use $gray-900.
$gray-900: #1e1e1e;
$gray-800: #2f2f2f;
$gray-700: #757575;     // Meets 4.6:1 text contrast against white.
$gray-600: #949494;     // Meets 3:1 UI or large text contrast against white.
$gray-400: #ccc;
$gray-300: #ddd;        // Used for most borders.
$gray-200: #e0e0e0;     // Used sparingly for light borders.
$gray-100: #f0f0f0;     // Used for light gray backgrounds.
$white: #fff;

// Opacities & additional colors.
$dark-theme-focus: $white;  // Focus color when the theme is dark.
$dark-gray-placeholder: rgba($gray-900, 0.62);
$medium-gray-placeholder: rgba($gray-900, 0.55);
$light-gray-placeholder: rgba($white, 0.65);

// Alert colors.
$alert-yellow: #f0b849;
$alert-red: #cc1818;
$alert-green: #4ab866;


/**
 * Fonts & basic variables.
 */

$default-font: -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell,"Helvetica Neue", sans-serif;
$default-font-size: 13px;
$default-line-height: 1.4;
$editor-html-font: Menlo, Consolas, monaco, monospace;
$editor-font-size: 16px;
$default-block-margin: 28px; // This value provides a consistent, contiguous spacing between blocks.
$text-editor-font-size: 15px;
$editor-line-height: 1.8;
$mobile-text-min-font-size: 16px; // Any font size below 16px will cause Mobile Safari to "zoom in".


/**
 * Grid System.
 * https://make.wordpress.org/design/2019/10/31/proposal-a-consistent-spacing-system-for-wordpress/
 */

$grid-unit: 8px;
$grid-unit-05: 0.5 * $grid-unit;    // 4px
$grid-unit-10: 1 * $grid-unit;      // 8px
$grid-unit-15: 1.5 * $grid-unit;    // 12px
$grid-unit-20: 2 * $grid-unit;      // 16px
$grid-unit-30: 3 * $grid-unit;      // 24px
$grid-unit-40: 4 * $grid-unit;      // 32px
$grid-unit-50: 5 * $grid-unit;      // 40px
$grid-unit-60: 6 * $grid-unit;      // 48px


/**
 * Dimensions.
 */

$icon-size: 24px;
$button-size: 36px;
$button-size-small: 24px;
$header-height: 60px;
$panel-header-height: $grid-unit-60;
$nav-sidebar-width: 300px;
$admin-bar-height: 32px;
$admin-bar-height-big: 46px;
$admin-sidebar-width: 160px;
$admin-sidebar-width-big: 190px;
$admin-sidebar-width-collapsed: 36px;
$modal-min-width: 360px;
$spinner-size: 18px;


/**
 * Shadows.
 */

$shadow-popover: 0 2px 6px rgba($black, 0.05);
$shadow-modal: 0 10px 10px rgba($black, 0.25);


/**
 * Editor widths.
 */

$sidebar-width: 280px;
$content-width: 840px;
$wide-content-width: 1100px;
$widget-area-width: 700px;


/**
 * Block & Editor UI.
 */

$block-toolbar-height: $grid-unit-60;
$border-width: 1px;
$border-width-focus: 2px; // This exists as a fallback, and is ideally overridden by var(--wp-admin-border-width-focus) unless in some SASS math cases.
$border-width-tab: 4px;
$helptext-font-size: 12px;
$radius-round: 50%;
$radius-block-ui: 2px;
$radio-input-size: 20px;
$radio-input-size-sm: 24px; // Width & height for small viewports.

// Deprecated, please avoid using these.
$block-padding: 14px; // Used to define space between block footprint and surrouding borders.


/**
 * Block paddings.
 */

// Padding for blocks with a background color (e.g. paragraph or group).
$block-bg-padding--v: 1.25em;
$block-bg-padding--h: 2.375em;


/**
 * React Native specific.
 * These variables do not appear to be used anywhere else.
 */

// Dimensions.
$mobile-header-toolbar-height: 44px;
$mobile-floating-toolbar-height: 44px;
$mobile-floating-toolbar-margin: 8px;
$mobile-color-swatch: 48px;

// Block UI.
$mobile-block-toolbar-height: 44px;
$dimmed-opacity: 1;
$block-edge-to-content: 16px;
$solid-border-space: 12px;
$dashed-border-space: 6px;
$block-selected-margin: 3px;
$block-selected-border-width: 1px;
$block-selected-padding: 0;
$block-selected-child-margin: 5px;
$block-selected-to-content: $block-edge-to-content - $block-selected-margin - $block-selected-border-width;

@function hex-to-rgb($hex) {
    @return red($hex), green($hex), blue($hex);
}


/**
 * Breakpoint mixins
 */

@mixin break-huge() {
    @media (min-width: #{ ($break-huge) }) {
        @content;
    }
}

@mixin break-wide() {
    @media (min-width: #{ ($break-wide) }) {
        @content;
    }
}

@mixin break-xlarge() {
    @media (min-width: #{ ($break-xlarge) }) {
        @content;
    }
}

@mixin break-large() {
    @media (min-width: #{ ($break-large) }) {
        @content;
    }
}

@mixin break-medium() {
    @media (min-width: #{ ($break-medium) }) {
        @content;
    }
}

@mixin break-small() {
    @media (min-width: #{ ($break-small) }) {
        @content;
    }
}

@mixin break-mobile() {
    @media (min-width: #{ ($break-mobile) }) {
        @content;
    }
}

@mixin break-zoomed-in() {
    @media (min-width: #{ ($break-zoomed-in) }) {
        @content;
    }
}


/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */

@mixin long-content-fade($direction: right, $size: 20%, $color: #fff, $edge: 0, $z-index: false) {
    content: "";
    display: block;
    position: absolute;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;

    @if $z-index {
        z-index: $z-index;
    }

    @if $direction == "bottom" {
        background: linear-gradient(to top, rgba($color, 0), $color 90%);
        left: $edge;
        right: $edge;
        top: $edge;
        bottom: calc(100% - $size);
        width: auto;
    }

    @if $direction == "top" {
        background: linear-gradient(to bottom, rgba($color, 0), $color 90%);
        top: calc(100% - $size);
        left: $edge;
        right: $edge;
        bottom: $edge;
        width: auto;
    }

    @if $direction == "left" {
        background: linear-gradient(to left, rgba($color, 0), $color 90%);
        top: $edge;
        left: $edge;
        bottom: $edge;
        right: auto;
        width: $size;
        height: auto;
    }

    @if $direction == "right" {
        background: linear-gradient(to right, rgba($color, 0), $color 90%);
        top: $edge;
        bottom: $edge;
        right: $edge;
        left: auto;
        width: $size;
        height: auto;
    }
}

/**
 * Focus styles.
 */

@mixin block-toolbar-button-style__focus() {
    box-shadow: inset 0 0 0 var(--wp-admin-border-width-focus) var(--wp-admin-theme-color), inset 0 0 0 4px color(white);

    // Windows High Contrast mode will show this outline, but not the box-shadow.
    outline: 2px solid transparent;
}

// Tabs, Inputs, Square buttons.
@mixin input-style__neutral() {
    box-shadow: 0 0 0 transparent;
    transition: box-shadow 0.1s linear;
    border-radius: $radius-block-ui;
    border: $border-width solid $gray-700;
    @include reduce-motion("transition");
}


@mixin input-style__focus() {
    border-color: var(--wp-admin-theme-color);
    box-shadow: 0 0 0 ($border-width-focus - $border-width) var(--wp-admin-theme-color);

    // Windows High Contrast mode will show this outline, but not the box-shadow.
    outline: 2px solid transparent;
}


/**
 * Applies editor left position to the selector passed as argument
 */

@mixin editor-left($selector) {
    #{$selector} { /* Set left position when auto-fold is not on the body element. */
        left: 0;

        @media (min-width: #{ ($break-medium + 1) }) {
            left: $admin-sidebar-width;
        }
    }

    .auto-fold #{$selector} { /* Auto fold is when on smaller breakpoints, nav menu auto collapses. */
        @media (min-width: #{ ($break-medium + 1) }) {
            left: $admin-sidebar-width-collapsed;
        }

        @media (min-width: #{ ($break-large + 1) }) {
            left: $admin-sidebar-width;
        }
    }

    /* Sidebar manually collapsed. */
    .folded #{$selector} {
        left: 0;

        @media (min-width: #{ ($break-medium + 1) }) {
            left: $admin-sidebar-width-collapsed;
        }
    }

    body.is-fullscreen-mode #{$selector} {
        left: 0 !important;
    }
}

/**
 * Styles that are reused verbatim in a few places
 */

// These are additional styles for all captions, when the theme opts in to block styles.
@mixin caption-style() {
    margin-top: 0.5em;
    margin-bottom: 1em;
}

@mixin caption-style-theme() {
    color: #555;
    font-size: $default-font-size;
    text-align: center;

    .is-dark-theme & {
        color: $light-gray-placeholder;
    }
}


/**
 * Allows users to opt-out of animations via OS-level preferences.
 */

@mixin reduce-motion($property: "") {

    @if $property == "transition" {
        @media (prefers-reduced-motion: reduce) {
            transition-duration: 0s;
            transition-delay: 0s;
        }
    }

    @else if $property == "animation" {
        @media (prefers-reduced-motion: reduce) {
            animation-duration: 1ms;
            animation-delay: 0s;
        }
    }

    @else {
        @media (prefers-reduced-motion: reduce) {
            transition-duration: 0s;
            transition-delay: 0s;
            animation-duration: 1ms;
            animation-delay: 0s;
        }
    }

}

@mixin input-control {
    font-family: $default-font;
    padding: 6px 8px;
    @include input-style__neutral();

    /* Fonts smaller than 16px causes mobile safari to zoom. */
    font-size: $mobile-text-min-font-size;
    /* Override core line-height. To be reviewed. */
    line-height: normal;
    @include break-small {
        font-size: $default-font-size;
        /* Override core line-height. To be reviewed. */
        line-height: normal;
    }

    &:focus {
        @include input-style__focus();
    }

    // Use opacity to work in various editor styles.
    &::-webkit-input-placeholder {
        color: $dark-gray-placeholder;
    }

    &::-moz-placeholder {
        opacity: 1; // Necessary because Firefox reduces this from 1.
        color: $dark-gray-placeholder;
    }

    &:-ms-input-placeholder {
        color: $dark-gray-placeholder;
    }
}

@mixin checkbox-control {
    @include input-control;
    border: $border-width solid $gray-900;
    margin-right: $grid-unit-15;
    transition: none;
    border-radius: $radius-block-ui;

    &:focus {
        box-shadow: 0 0 0 ($border-width * 2) color(white), 0 0 0 ($border-width * 2 + $border-width-focus) var(--wp-admin-theme-color);

        // Only visible in Windows High Contrast mode.
        outline: 2px solid transparent;
    }

    &:checked {
        background: var(--wp-admin-theme-color);
        border-color: var(--wp-admin-theme-color);

        // Hide default checkbox styles in IE.
        &::-ms-check {
            opacity: 0;
        }
    }

    &:checked::before,
    &[aria-checked="mixed"]::before {
        margin: -3px -5px;
        color: color(white);

        @include break-medium() {
            margin: -4px 0 0 -5px;
        }
    }

    &[aria-checked="mixed"] {
        background: var(--wp-admin-theme-color);
        border-color: var(--wp-admin-theme-color);

        &::before {
            // Inherited from `forms.css`.
            // See: https://github.com/WordPress/wordpress-develop/tree/5.1.1/src/wp-admin/css/forms.css#L122-L132
            content: "\f460";
            float: left;
            display: inline-block;
            vertical-align: middle;
            width: 16px;
            /* stylelint-disable */
            font: normal 30px/1 dashicons;
            /* stylelint-enable */
            speak: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            @include break-medium() {
                float: none;
                font-size: 21px;
            }
        }
    }
}

@mixin radio-control {
    @include input-control;
    border: $border-width solid $gray-900;
    margin-right: $grid-unit-15;
    transition: none;
    border-radius: $radius-round;
    width: $radio-input-size-sm;
    height: $radio-input-size-sm;

    @include break-small() {
        height: $radio-input-size;
        width: $radio-input-size;
    }

    &:checked::before {
        box-sizing: inherit;
        width: 8px;
        height: 8px;
        transform: translate(7px, 7px);
        margin: 0;
        background-color: color(white);

        // This border serves as a background color in Windows High Contrast mode.
        border: 4px solid color(white);

        @include break-small() {
            transform: translate(5px, 5px);
        }
    }

    &:focus {
        box-shadow: 0 0 0 ($border-width * 2) color(white), 0 0 0 ($border-width * 2 + $border-width-focus) var(--wp-admin-theme-color);

        // Only visible in Windows High Contrast mode.
        outline: 2px solid transparent;
    }

    &:checked {
        background: var(--wp-admin-theme-color);
        border-color: var(--wp-admin-theme-color);
    }
}

/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
@mixin reset {
    box-sizing: border-box;

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }
}

/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
@mixin wp-admin-reset( $content-container ) {
    background: color(white);

    #wpcontent {
        padding-left: 0;
    }

    #wpbody-content {
        padding-bottom: 0;
    }

    /* We hide legacy notices in Gutenberg Based Pages, because they were not designed in a way that scaled well.
       Plugins can use Gutenberg notices if they need to pass on information to the user when they are editing. */
    #wpbody-content > div:not(#{ $content-container }):not(#screen-meta) {
        display: none;
    }

    #wpfooter {
        display: none;
    }

    .a11y-speak-region {
        left: -1px;
        top: -1px;
    }

    ul#adminmenu a.wp-has-current-submenu::after,
    ul#adminmenu > li.current > a.current::after {
        border-right-color: color(white);
    }

    .media-frame select.attachment-filters:last-of-type {
        width: auto;
        max-width: 100%;
    }
}

@mixin admin-scheme($color-primary) {
    // Define RGB equivalents for use in rgba function.
    // Hexadecimal css vars do not work in the rgba function.
    --wp-admin-theme-color: #{$color-primary};
    --wp-admin-theme-color--rgb: #{hex-to-rgb($color-primary)};
    // Darker shades.
    --wp-admin-theme-color-darker-10: #{darken($color-primary, 5%)};
    --wp-admin-theme-color-darker-10--rgb: #{hex-to-rgb(darken($color-primary, 5%))};
    --wp-admin-theme-color-darker-20: #{darken($color-primary, 10%)};
    --wp-admin-theme-color-darker-20--rgb: #{hex-to-rgb(darken($color-primary, 10%))};

    // Focus style width.
    // Avoid rounding issues by showing a whole 2px for 1x screens, and 1.5px on high resolution screens.
    --wp-admin-border-width-focus: 2px;
    @media ( -webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        --wp-admin-border-width-focus: 1.5px;
    }
}

@mixin wordpress-admin-schemes() {
    body.admin-color-light {
        @include admin-scheme(#0085ba);
    }

    body.admin-color-modern {
        @include admin-scheme(#3858e9);
    }

    body.admin-color-blue {
        @include admin-scheme(#096484);
    }

    body.admin-color-coffee {
        @include admin-scheme(#46403c);
    }

    body.admin-color-ectoplasm {
        @include admin-scheme(#523f6d);
    }

    body.admin-color-midnight {
        @include admin-scheme(#e14d43);
    }

    body.admin-color-ocean {
        @include admin-scheme(#627c83);
    }

    body.admin-color-sunrise {
        @include admin-scheme(#dd823b);
    }
}

// Deprecated from UI, kept for back-compat.
@mixin background-colors-deprecated() {
    .has-very-light-gray-background-color {
        background-color: #eee;
    }

    .has-very-dark-gray-background-color {
        background-color: #313131;
    }
}

// Deprecated from UI, kept for back-compat.
@mixin foreground-colors-deprecated() {
    .has-very-light-gray-color {
        color: #eee;
    }

    .has-very-dark-gray-color {
        color: #313131;
    }
}

// Deprecated from UI, kept for back-compat.
@mixin gradient-colors-deprecated() {
    /*
     * Our classes uses the same values we set for gradient value attributes,
     * and we can not use spacing because of WP multi site kses rule.
     */

    /* stylelint-disable function-comma-space-after */
    .has-vivid-green-cyan-to-vivid-cyan-blue-gradient-background {
        background: linear-gradient(135deg,rgba(0,208,132,1) 0%,rgba(6,147,227,1) 100%);
    }

    .has-purple-crush-gradient-background {
        background: linear-gradient(135deg,rgb(52,226,228) 0%,rgb(71,33,251) 50%,rgb(171,29,254) 100%);
    }

    .has-hazy-dawn-gradient-background {
        background: linear-gradient(135deg,rgb(250,172,168) 0%,rgb(218,208,236) 100%);
    }

    .has-subdued-olive-gradient-background {
        background: linear-gradient(135deg,rgb(250,250,225) 0%,rgb(103,166,113) 100%);
    }

    .has-atomic-cream-gradient-background {
        background: linear-gradient(135deg,rgb(253,215,154) 0%,rgb(0,74,89) 100%);
    }

    .has-nightshade-gradient-background {
        background: linear-gradient(135deg,rgb(51,9,104) 0%,rgb(49,205,207) 100%);
    }

    .has-midnight-gradient-background {
        background: linear-gradient(135deg,rgb(2,3,129) 0%,rgb(40,116,252) 100%);
    }
    /* stylelint-enable function-comma-space-after */
}

/*@import "./audio/style.scss";*/
@import "./button/style.scss";
@import "./buttons/style.scss";
/*@import "./code/style.scss";*/
@import "./columns/style.scss";
@import "./cover/style.scss";
@import "./embed/style.scss";
/*@import "./file/style.scss";*/
@import "./gallery/style.scss";
@import "./group/style.scss";
@import "./heading/style.scss";
@import "./image/style.scss";
@import "./list/style.scss";
@import "./media-text/style.scss";
/*@import "./navigation/style.scss";*/
/*@import "./navigation-link/style.scss";*/
/*@import "./page-list/style.scss";*/
@import "./paragraph/style.scss";
/*@import "./preformatted/style.scss";*/
/*@import "./pullquote/style.scss";*/
/*@import "./query-pagination/style.scss";*/
@import "./quote/style.scss";
/*@import "./rss/style.scss";*/
@import "./separator/style.scss";
/*@import "./site-logo/style.scss";*/
/*@import "./social-links/style.scss";*/
@import "./spacer/style.scss";
@import "./table/style.scss";
@import "./text-columns/style.scss";
/*@import "./verse/style.scss";*/
@import "./video/style.scss";

@import "common.scss";
