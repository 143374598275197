@function color($color) {
    @return map-get($colors, $color);
  }
    
  // FONT SIZES
  
  @function calculateRem($size) {
    $remSize: $size / 16px;
    @return $remSize * 1rem;
  }
  
  
  
  @mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
  }
  
  @mixin transform($args...) {
    -webkit-transform: $args;
    -moz-transform: $args;
    -ms-transform: $args;
    -o-transform: $args;
    transform: $args;
  }
  
  @mixin animation($args){
    -webkit-animation: $args;
    -moz-animation:    $args;
    -o-animation:      $args;
    animation:         $args;
  }
  
  
  @mixin transform-origin($args...) {
    -webkit-transform-origin: $args;
    -moz-transform-origin: $args;
    -ms-transform-origin: $args;
    -o-transform-origin: $args;
    transform-origin: $args;
  }
  
  
  
  // OPACITY
  
  @mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
  }
  
  
  
  
  // usage @extend %visuallyhidden;
  
  
  @mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
  }
  
  @mixin transform($args...) {
    -webkit-transform: $args;
    -moz-transform: $args;
    -ms-transform: $args;
    -o-transform: $args;
    transform: $args;
  }
  
  
  @mixin transform-origin($args...) {
    -webkit-transform-origin: $args;
    -moz-transform-origin: $args;
    -ms-transform-origin: $args;
    -o-transform-origin: $args;
    transform-origin: $args;
  }
  
  
  
  @mixin element($element) {
      &__#{$element} {
          @content;
      }
  }
  
  @mixin modifier($modifier) {
      &--#{$modifier} {
          @content;
      }
  }
  
  @mixin e($element) {
      @include element($element)  {
          @content;
      }
  }
  
  @mixin m($modifier) {
      @include modifier($modifier) {
          @content;
      }
  }
  
  @mixin hover {
      &__#{$element} {
          @content;
      }
  }
  
  @mixin linear-gradient($direction, $color-stops...) {
    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
  }
  
  