.wpcf7{
    position: relative;
    z-index: 10;
    .required{
        @apply font-light text-gray-500;
    }
    label{
        @apply block text-black text-xs font-bold mb-4;
        &.nomarrgin{
            @apply mb-0;
        }
        @screen lg{
            @apply text-sm;
        }
    }
    .wpcf7-form-control-wrap{
        @apply mt-2 block relative;
    }
    .element-row{
        &:last-child{
            @apply border-b-0;
        }
    }
    .element-inner-checkbox{
        &:disabled{
            @apply opacity-50;
        }
    }
    input[type=text], input[type=email], textarea, [type='url'], [type='password'], [type='number'], [type='date'], [type='datetime-local'], input[type='month'], input[type='week'], input[type='time'], input[type='search'], input[type='tel'], select, select[multiple]{
        @apply mt-1 font-light block w-full rounded-md bg-gray-100 border-transparent;
        @apply text-sm;
        @screen lg{
            @apply text-base;
        }
        &:focus{
            @apply border-primary bg-white ring-0;
        }
    }
    textarea{
        max-height: calculateRem(130px);
        resize: none;
    }
    input[type='checkbox'], input[type='radio']{
        @apply rounded relative w-5 h-5 bg-gray-200 border-transparent text-primary mr-3;
        top:  calculateRem(-1px);
        &:focus{
            @apply ring-1 ring-offset-2 ring-primary border-transparent;
        }
        &:checked{
            @apply border-transparent bg-primary;
        }
    }
    .wpcf7-submit{
        @apply bg-primary py-2 rounded-full font-bold text-sm block text-center w-full text-white;
        border: 0;
        cursor: pointer;
        @screen md{
            @apply py-4;
        }
        &:hover{
            @apply bg-black;
        }
    }
    .wpcf7-not-valid-tip{
        color: rgba(0,0,0,0.4);
        @apply text-xs mt-2 block;
    }
    .screen-reader-response{
        display: none;
    }
    .wpcf7-response-output{
        text-align: center;
        margin-top: calculateRem(25px);
        @apply text-sm font-medium text-primary;
        @screen lg{
            @apply text-base;
        }
    }
    .wpcf7-list-item{
        &.inline-block{
            @apply inline;
        }
    }


}
