// The following selectors have increased specificity (using the :root prefix)
// to assure colors take effect over another base class color, mainly to let
// the colors override the added specificity by link states such as :hover.

:root {
	@include background-colors-deprecated();
	@include foreground-colors-deprecated();
	@include gradient-colors-deprecated();
	// This CSS Custom Properties aren't used anymore as defaults,
	// but we still need to keep them for backward compatibility.
	--wp--preset--font-size--normal: 16px;
	--wp--preset--font-size--huge: 42px;
}

// Font sizes (not used now, kept because of backward compatibility).
.has-regular-font-size {
	font-size: 1em;
}

.has-larger-font-size {
	font-size: 2.625em;
}

.has-normal-font-size {
	font-size: var(--wp--preset--font-size--normal) !important;
}

.has-huge-font-size {
	font-size: var(--wp--preset--font-size--huge) !important;
}

// Text alignments.
.has-text-align-center {
	text-align: center;
}

.has-text-align-left {
	/*rtl:ignore*/
	text-align: left;
}

.has-text-align-right {
	/*rtl:ignore*/
	text-align: right;
}

// This tag marks the end of the styles that apply to editing canvas contents and need to be manipulated when we resize the editor.
#end-resizable-editor-section {
	display: none;
}

// Block alignments.
.aligncenter {
	clear: both;
}

// Justification.
.items-justified-left {
	justify-content: flex-start;
}

.items-justified-center {
	justify-content: center;
}

.items-justified-right {
	justify-content: flex-end;
}

.items-justified-space-between {
	justify-content: space-between;
}

.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	-webkit-clip-path: inset(50%);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal !important;
}

.screen-reader-text:focus {
	background-color: $gray-300;
	clip: auto !important;
	clip-path: none;
	color: #444;
	display: block;
	font-size: 1em;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
}

/**
 * The following provide a simple means of applying a default border style when
 * a user first makes a selection in the border block support panel.
 * This prevents issues such as where the user could set a border width
 * and see no border due there being no border style set.
 *
 * This is intended to be removed once intelligent defaults can be set while
 * making border selections via the block support.
 *
 * See: https://github.com/WordPress/gutenberg/pull/33743
 */
html :where(.has-border-color) {
	border-style: solid;
}

html :where([style*="border-width"]) {
	border-style: solid;
}
