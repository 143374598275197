// This variable is repeated across Button, Buttons, and Buttons editor styles.
$blocks-block__margin: 0.5em;

// Prefer the link selector instead of the regular button classname
// to support the previous markup in addition to the new one.
.wp-block-button__link {
	@apply bg-black text-white text-sm font-semibold px-5 py-3;
	box-shadow: none;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	text-decoration: none;
	word-break: break-word; // overflow-wrap doesn't work well if a link is wrapped in the div, so use word-break here.
	box-sizing: border-box;
	@screen lg{
		@apply px-6 py-4;
	}
	&:hover,
	&:focus,
	&:active,
	&:visited {
		color: $white;
	}

	&.aligncenter {
		text-align: center;
	}

	&.alignright {
		/*rtl:ignore*/
		text-align: right;
	}
}

// Increased specificity needed to override margins.
.wp-block-buttons > .wp-block-button {
	&.has-custom-width {
		max-width: none;
		.wp-block-button__link {
			width: 100%;
		}
	}

	&.has-custom-font-size {
		.wp-block-button__link {
			font-size: inherit;
		}
	}

	&.wp-block-button__width-25 {
		width: calc(25% - (var(--wp--style--block-gap, #{$blocks-block__margin}) * 0.75));
	}

	&.wp-block-button__width-50 {
		width: calc(50% - (var(--wp--style--block-gap, #{$blocks-block__margin}) * 0.5));
	}

	&.wp-block-button__width-75 {
		width: calc(75% - (var(--wp--style--block-gap, #{$blocks-block__margin}) * 0.25));
	}

	&.wp-block-button__width-100 {
		width: 100%;
		flex-basis: 100%;
	}
}

// For vertical buttons, gap is not factored into width calculations.
.wp-block-buttons.is-vertical > .wp-block-button {
	&.wp-block-button__width-25 {
		width: 25%;
	}

	&.wp-block-button__width-50 {
		width: 50%;
	}

	&.wp-block-button__width-75 {
		width: 75%;
	}
}
