.features-list-product{
    &__elem{
        &:last-of-type{
            @apply border-b-0;
        }

    }
    &.even{
        @screen md{
            &:nth-last-of-type(-n+2){
                .features-list-product__elem__inner{
                    @apply border-b-0;
                }
            }
        }
    }
}



.thumbs-gallery{
    .thumb-elem{
        @apply cursor-pointer bg-white;
        img{
            @apply opacity-40;
        }
    }
    .swiper-slide-thumb-active{
        .thumb-elem{
            @apply border-gray-400;
            img{
                @apply opacity-100;
            }
        }
    }
}

.title-vertical-separator{
    &:after{
        content: '';
        @apply block mx-auto bg-primary mt-5;
        width: calculateRem(1px);
        height: calculateRem(50px);
        @screen lg{
            @apply mt-12;
        }
    }
}

.product-image{
    @apply relative overflow-hidden block;
    &:after {
        content: "";
        @apply absolute inset-0 block border-0 border-primary;
        @include transition(all .1s);
    }
    &:before{
        content: "";
        display: block;
        padding-bottom: 100%;
    }
    &__inner{
        &:after{
            @apply absolute inset-0 block border-0 border-light;
            content: "";
            @include transition(all .3s $transitioneffect);
        }
    }
    &:hover{
        &:after{
            border-width: calculateRem(1px);
        }
        .product-image__inner{
            &:after{
                border-width: calculateRem(20px);
            }
        }
    }
}

.swatch-item{
    &.active{
        @apply opacity-50 font-semibold;
        &:hover{
            @apply opacity-50;
        }
    }
}

.max-product{
    max-width: calculateRem(1600px);
    margin: 0 auto;
}

.page-navi{
    @apply text-center mb-4 flex mx-auto justify-center;

    .page-numbers{
        @apply flex items-center w-8 h-6 font-semibold justify-center;
        &:hover{
            @apply text-primary;
        }
        &.dots{
            @apply font-light text-gray-500 font-light;
            &:hover{
                @apply text-gray-500;
            }
        }
        &.current{
            @apply text-primary;
        }
        &.prev{
            @apply pr-3 mr-3 border-r border-primary font-light w-auto;
        }
        &.next{
            @apply pl-3 mr-3 border-l border-primary font-light w-auto;
        }


    }

}


