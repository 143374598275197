/* stylelint-disable */
[data-aos] {
    @for $i from 1 through 60 {
      body[data-aos-duration='#{$i * 50}'] &,
      &[data-aos][data-aos-duration='#{$i * 50}'] {
        transition-duration: #{$i * 50}ms;
      }
  
      body[data-aos-delay='#{$i * 50}'] &,
      &[data-aos][data-aos-delay='#{$i * 50}'] {
        transition-delay: 0s;
  
        &.aos-animate {
          transition-delay: 0ms;
          @screen md{
            transition-delay: #{$i * 50}ms;
          }
        }
      }
    }
  }
  
  [data-aos] {
    pointer-events: none;
    &.aos-animate {
      pointer-events: auto;
    }
  }
  
  $aos-easing: (
    outexpo: cubic-bezier(.19,1,.22,1),
  );
  
  [data-aos] {
    @each $key, $val in $aos-easing {
      body[data-aos-easing="#{$key}"] &,
      &[data-aos][data-aos-easing="#{$key}"] {
        transition-timing-function: $val;
      }
    }
  }
  
  $aos-distance: 50px !default;
  
  @media screen {
    html:not(.no-js) {
      [data-aos^='fade'][data-aos^='fade'] {
        opacity: 0;
        transition-property: opacity, transform;
        &.aos-animate {
          opacity: 1;
          transform: none;
        }
      }
      [data-aos^='fade'][data-aos^='fade'] {
        opacity: 0;
        transition-property: opacity, transform;
  
        &.aos-animate {
          opacity: 1;
          transform: none;
        }
      }
      [data-aos='fade-up'] {
        transform: translate3d(0, $aos-distance, 0);
      }
      [data-aos='fade-right'] {
        transform: translate3d(-$aos-distance, 0, 0);
      }
      [data-aos='fade-left'] {
        transform: translate3d($aos-distance, 0, 0);
      }
    }
  }
  /* stylelint-enable */
  
