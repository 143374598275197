@import 'tailwindcss/base';

@import 'common/mixins';
@import 'common/variables';


@import 'common/global';
@import 'gutenberg/style';

@import 'partials';
@import 'components';
@import 'external';

@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.sticky{
  position: sticky;
  position: -webkit-sticky;
}
