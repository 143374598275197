

.nav-tabs {
    > li {
        @apply my-1 mx-1;
        @screen md{
            @apply mx-2;
        }
        @screen lg{
            @apply mx-3;
        }
        @screen xl{
            @apply mx-4;
        }
        @screen 2xl{
            @apply mx-6;
        }
        > a {
            @apply text-black inline-block uppercase font-semibold border border-transparent text-xs rounded-full px-5 py-2;
            @include transition(none);
            @screen md{
                @apply text-sm;
            }
            @screen lg{
                @apply px-8 py-3;
            }
            &:hover {
                @apply border-primary;
            }

            &.active {
                @apply bg-primary border-primary text-white;
                &:hover,
                &:focus {
                    @apply bg-primary border-primary text-white;
                }
            }


        }


    } 
}

.tabs-content {

    > .tab-pane {
        display: none;

        &.active {
            display: block;
        }
    }
}

