.hero-home{
    @apply bg-black;
    height: 70vh;
    @screen md{
        height: 85vh;
    }
    @screen lg{
        height: calc(100vh - 86px);
        &--with-banner{
            height: calc(100vh - 123px);
        }

    }

    .link-slide{
        @apply opacity-40;

        &.active{
            @apply opacity-100;
        }
    }
    &--overlay{
        @include linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.8));
        @apply absolute inset-0 z-10 opacity-50;
    }
}

.hero-titles{
	cursor: default;
	position: relative;
    &__elem{
        @apply absolute left-0 right-0 bottom-0 opacity-0;
        height: 0;
        pointer-events: none;
        .btn-cont{

            overflow: hidden;
            position: relative;
        }
        .hero-titles__item__line {
            position: relative;
            overflow: hidden;
            padding: calculateRem(5px) 0;
        }
        &--current{
            @apply opacity-100;
            height: auto;
            pointer-events: auto;
        }
    }


    
}




