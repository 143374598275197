.breadcrumbs{
    @apply mb-4 text-gray-300 uppercase text-xs tracking-wider;
    @screen sm{
        @apply text-sm;
    }
    a{
        @apply font-semibold text-black border-b-2 border-transparent;
        &:hover{
            @apply border-primary;
        }

    }
    .breadcrumb_last{
        @apply text-black;
    }
    &--light{
        @apply text-white;
        a{
            @apply text-white;
        }
        .breadcrumb_last{
            @apply text-white;
        }
    }


}
